import BlogArticlesList from "./BlogArticlesList";
import useBlogPosts from "../../../../hooks/useBlogPosts";
import SparkSection from "../home/SparkSection";

const BlogDocument = ({ category = "latest", pageTitle }) => {
	const articles = useBlogPosts();

	return (
		<div className="page-blog-v2">
			<BlogArticlesList articles={articles} category={category} pageTitle={pageTitle} />
			<div className="container">
				<div className="mb-6 mb-md-20 border-top border-dark" />
			</div>

			<SparkSection titleVariant="latest" />
			<div className="mb-6 mb-md-12" />
		</div>
	);
};

export default BlogDocument;
