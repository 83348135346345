import { useMemo } from "react";
import moment from "moment";
import { getOrElse } from "../../../src/_helpers/_utilities";

const useSortedArticles = (articles, sort) => {
  const sortByLatest = (articlesList) => {
    return articlesList.sort((a, b) =>
      moment(a.published).isAfter(moment(b.published)) ? -1 : 1
    );
  };

  const sortByCategory = () => {
    const filtered = articles.filter((a) => {
      return getOrElse(
        () => a.category.toLowerCase().replace(/\s/g, "-") === sort,
        false
      );
    });
    return sortByLatest(filtered);
  };

  return useMemo(() => {
    switch (sort) {
      case "latest":
        return sortByLatest(articles);
      default:
        return sortByCategory();
    }
  }, [sort, articles, sortByLatest]);
};

export default useSortedArticles;
