import Section from "../../../section/Section";
import useSortedArticles from "../../../../hooks/useSortedArticles";
import Article from "../../../v2/Article";

const BlogArticlesList = ({ articles = [], category = "latest", pageTitle }) => {
	const sortedArticles = useSortedArticles(articles, category);

	return (
		<Section className="pt-8 pt-md-20" smallContainer={true}>
			<div className="text-center">
				<h1 className="mx-auto pb-10 pb-md-12">{pageTitle}</h1>
			</div>

			<div className="row">
				{sortedArticles.map((article) => {
					return <Article key={article.slug} article={article} />;
				})}
			</div>
		</Section>
	);
};

export default BlogArticlesList;
