import Document from "../../components/v2/Document";
import BlogDocument from "../../components/_pages/v2/blog/BlogDocument";

const Blog = () => (
	<Document
		title="Blog - Confidence Building"
		ogData={{
			title: "Stay Nimble. Our Confidence Building Articles. - Stay Nimble",
			description: "Find articles that help you build your confidence.",
			image: "https://s3.eu-west-2.amazonaws.com/static.staynimble.co.uk/assets/open-graph/preview.jpg",
			url: "/blog/confidence-building/",
		}}
	>
		<BlogDocument category="confidence-building" pageTitle="Confidence Building" />
	</Document>
);

export default Blog;
